import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  Button,
  Breadcrumbs,
  Typography,
  Link,
} from '@material-ui/core';
import MyAccountDropdown from './MyAccountDropdown';
import { useStyles } from '../../../style/header';
import { accountsFromLS, selectedChannelFromLS } from '../../../utils/helpers';
import Breadcrumb from './Breadcrumb';
import { NavigateNext } from '@material-ui/icons';
import { defaultValues } from '../../../constants';

export default function Header() {
  const classes = useStyles();
  const history = useHistory();
  const [accounts, setAccounts] = useState([]);
  const channelName = selectedChannelFromLS();

  useEffect(() => {
    setAccounts(accountsFromLS());
  }, []);

  const showBreadcrumbs = () => {
    if (history.location.pathname === '/channels') {
      return <Breadcrumb currentPage={'Channels'} />;
    } else if (history.location.pathname === '/') {
      return <Breadcrumb currentPage={''} />;
    } else if (history.location.pathname === '/users') {
      return <Breadcrumb currentPage={'Users'} />;
    } else if (history.location.pathname === '/text-moderation') {
      return <Breadcrumb currentPage={'Text Moderation'} />;
    } else if (history.location.pathname === '/image-moderation') {
      return <Breadcrumb currentPage={'Image Moderation'} />;
    } else if (history.location.pathname === '/channels/messages') {
      return (
        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link color="primary" href="/">
            <Typography color="primary" variant="body2">
              {defaultValues.KEY_NAME}
            </Typography>
          </Link>
          <Link color="primary" href="/channels">
            <Typography color="primary" variant="body2">
              Channels
            </Typography>
          </Link>
          <Typography color="inherit" variant="body2">
            {channelName}
          </Typography>
        </Breadcrumbs>
      );
    }
  };

  const handleContactusClick = () => {
    const newWindow = window.open(
      defaultValues.PUBNUB_SALES_URL,
      '_blank',
      'noopener,noreferrer'
    );
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.root}>
        <Toolbar>
          <IconButton
            id="toDashboard"
            edge="start"
            onClick={() => history.push('/')}
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <img
              src={process.env.PUBLIC_URL + '/images/Pubnub logo.svg'}
              width={90}
              alt=""
            />
          </IconButton>
          <div className={classes.verticalLine} />
          {showBreadcrumbs()}
          <div className={classes.grow} />
          <Grid item>
            <Button className={classes.button} onClick={handleContactusClick}>
              Contact us
            </Button>
          </Grid>
          <Grid item>
            <div className={classes.verticalLine} />
          </Grid>
          <Grid item className={classes.headerMenu}>
            <MyAccountDropdown accounts={accounts} />
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}
