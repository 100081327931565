import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from '../../style/imageModeration.js';
import SwitchButton from '../core/SwitchButton';
import FilterCard from './FilterCard';
import AutomaticImageModeration from './automaticModeration/AutomaticImageModeration';
import { sightEngineSettings } from '../../config/index.js';

import { actionCompleted } from "pubnub-demo-integration";
import { getSessionStorage } from "../../services/localStorage";
const ImageModeration = () => {
  const classes = useStyles();
  const [state, setState] = useState({
    applyToAllChannelIds: true,
    initialLoading: true,
    imageModerationToggle: true,
    channelId: '*',
    channelIdError: false,
    sightengineAPIUserError: false,
    sightengineAPIKeyError: false,
    sightengineWorkflowIdError: false,
    channelOnChange: false,
    toolForImageModeration: 'sightengine',
    sightengineAPIUser: '',
    sightengineAPIKey: '',
    sightengineWorkflowId: '',
    sightengineRiskFactorThreshold:
      sightEngineSettings.sightengineRiskFactorThreshold,
    reRouteMessages: 'true',
    error: {
      status: false,
      msg: '',
    },
    successMsg: '',
    errorMsg: '',
    successStatus: false,
    errorStatus: false,
    saveLoading: false,
    channelIdDisabled: false,
  });

  //  Interactive Demo Only
  actionCompleted({ action: "View Image Moderation", windowLocation: getSessionStorage("originalUrl") });
  //  Interactive Demo Only
  return (
    <>
      <Typography testid="title" variant="h6" className={classes.title}>
        Image Moderation
        <SwitchButton
          checked={state.imageModerationToggle}
          className="tour-image-toggle"
        />
      </Typography>
      <br />
      <Typography testid="subTitle" className={classes.subTitle}>
        Profanity detection method
      </Typography>
      <FilterCard state={state} />
      <br />
      <AutomaticImageModeration state={state} setState={setState} />
      <br />
    </>
  );
};
export default ImageModeration;
