import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import { useStyles } from '../../style/sidebar';
import {
  DescriptionOutlined,
  HomeOutlined,
  MailOutline,
  ChatBubbleOutline,
  PersonOutlined,
  SettingsOutlined,
  ExpandMore,
  ExpandLess,
  OpenInNewOutlined,
} from '@material-ui/icons';
import { defaultValues } from '../../constants';
import GitHubIcon from '@material-ui/icons/GitHub';
import AlertDialog from './AlertDialog';

function Sidebar({ content }) {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const openDemoApp = () => {
    const newWindow = window.open(
      defaultValues.MODERATED_CHAT_APP_URL,
      '_blank',
      'noopener,noreferrer'
    );
    if (newWindow) newWindow.opener = null;
    setShowDialog(true);
  };

  const openGitRepo = () => {
    const newWindow = window.open(
      defaultValues.GIT_REPO_LINK,
      '_blank',
      'noopener,noreferrer'
    );
    if (newWindow) newWindow.opener = null;
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <List disablePadding={true}>
        <ListItem
          id="overviewItem"
          onClick={() => history.push('/')}
          selected={history.location.pathname === '/'}
          button
        >
          <ListItemIcon className={classes.listItemRoot}>
            <HomeOutlined className={classes.overviewIcon} />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">Overview</Typography>
          </ListItemText>
        </ListItem>

        <ListItem
          id="settingsItem"
          className="tour-settings"
          button
          onClick={handleClick}
        >
          <ListItemIcon className={classes.listItemRoot}>
            <SettingsOutlined className={classes.settingsIcon} />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">Settings</Typography>
          </ListItemText>
          {open ? (
            <ExpandLess className="tour-image-moderation" />
          ) : (
            <ExpandMore className="tour-image-moderation" />
          )}
        </ListItem>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              onClick={() => history.push('/text-moderation')}
              selected={history.location.pathname === '/text-moderation'}
            >
              <ListItemIcon className={classes.settingsItemRoot}>
                <DescriptionOutlined className={classes.textModerationIcon} />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2">Text Moderation</Typography>
              </ListItemText>
            </ListItem>

            <ListItem button onClick={() => history.push('/image-moderation')}>
              <ListItemIcon className={classes.settingsItemRoot}>
                <ChatBubbleOutline className={classes.chatIcon} />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2">Image Moderation</Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Collapse>

        <ListItem
          id="usersItem"
          button
          onClick={() => history.push('/users')}
          selected={history.location.pathname === '/users'}
          className="tour-users"
        >
          <ListItemIcon className={classes.listItemRoot}>
            <PersonOutlined className={classes.userIcon} />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">Users</Typography>
          </ListItemText>
        </ListItem>

        <ListItem
          id="channelsItem"
          button
          onClick={() => history.push('/channels')}
          selected={history.location.pathname === '/channels'}
          className="tour-channels"
        >
          <ListItemIcon className={classes.listItemRoot}>
            <MailOutline className={classes.channelIcon} />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">Channels</Typography>
          </ListItemText>
        </ListItem>

        <ListItem button onClick={openDemoApp} className="tour-view-chat">
          <ListItemIcon className={classes.listItemRoot}>
            <OpenInNewOutlined className={classes.demoAppIcon} />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">View as Chat User</Typography>
          </ListItemText>
        </ListItem>
        <ListItem button onClick={openGitRepo} className="tour-view-repo">
          <ListItemIcon className={classes.listItemRoot}>
            <GitHubIcon className={classes.repoIcon} />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">View Repository</Typography>
          </ListItemText>
        </ListItem>
      </List>
    </div>
  );
  return (
    <>
      <div className={classes.root}>
        <nav className={classes.drawer}>{drawer}</nav>
        <main className={classes.content}>{content}</main>
      </div>
      <AlertDialog
        open={showDialog}
        setOpen={setShowDialog}
        viewChatAppContent={
          'Using the chat application you can now write messages and experience moderation in action. To view the messages navigate to the appropriate channel on the channels page. '
        }
      />
    </>
  );
}

export default Sidebar;
