export const tourStyle = {
  tooltipContainer: {
    textAlign: 'left',
    font: 'var(--unnamed-font-style-normal) normal medium 24px/29px var(--unnamed-font-family-proxima-nova)',
    letterSpacing: 'var(--unnamed-character-spacing-0)',
    color: '#414141',
    opacity: 1,
  },
  buttonNext: {
    background: '#6387ED 0% 0% no-repeat padding-box',
    borderRadius: '8px',
    opacity: 1,
    minWidth: '100px',
    font: 'var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-12)/var(--unnamed-line-spacing-15) var(--unnamed-font-family-proxima-nova)',
    letterSpacing: 'var(--unnamed-character-spacing-0)',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#FFFFFF',
  },
  buttonBack: {
    borderRadius: '8px',
    border: '1px solid #707070',
    opacity: 1,
    minWidth: '100px',
    font: 'var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-12)/var(--unnamed-line-spacing-15) var(--unnamed-font-family-proxima-nova)',
    letterSpacing: 'var(--unnamed-character-spacing-0)',
    textAlign: 'center',
    color: '#707070',
    textTransform: 'uppercase',
  },
  options: {
    textColor: '#414141',
  },
};
