import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { defaultValues } from '../../constants';

export default function AlertDialog({
  open,
  setOpen,
  message,
  viewChatAppContent,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  const content = viewChatAppContent || (
    <>
      <span> {message} Download the </span>
      <a
        style={{ fontWeight: 600, color: 'black', textDecorationLine: 'none' }}
        href={defaultValues.GIT_REPO_LINK}
        target="_blank"
        rel="noopener noreferrer"
      >
        repository
      </a>
      <span> to try it yourself or </span>
      <a
        style={{ fontWeight: 600, color: 'black', textDecorationLine: 'none' }}
        href={defaultValues.PUBNUB_SALES_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        contact us
      </a>
      <span> to explore all of the features we offer.</span>
    </>
  );

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
