import React from 'react';
import { Avatar, Button } from '@material-ui/core';
import { useStyles } from '../../../style/myAccountDropdown';
import { capitalizeFirstCharacter } from '../../../utils/helpers';
import { KeyboardArrowDownOutlined } from '@material-ui/icons';
import { defaultValues } from '../../../constants';

export default function MyAccountDropdown({ accounts }) {
  const classes = useStyles();
  return (
    <>
      <Button
        className={classes.button}
        endIcon={<KeyboardArrowDownOutlined />}
      >
        <Avatar className={classes.avatar} variant="square">
          {capitalizeFirstCharacter(defaultValues.USER_NAME.slice(0, 2))}
        </Avatar>
        {defaultValues.USER_NAME}
      </Button>
    </>
  );
}
