import React, { useReducer, useEffect } from 'react';
import JoyRide, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { useHistory } from 'react-router-dom';
import { defaultValues } from '../../constants';
import { TOUR_STEPS } from '../../constants/tour';
import { tourStyle } from '../../style/tour';

// Define our state
const INITIAL_STATE = {
  key: new Date(),
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  steps: TOUR_STEPS,
};

// Set up the reducer function
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'START':
      return { ...state, run: true };
    case 'RESET':
      return { ...state, stepIndex: 0 };
    case 'STOP':
      return { ...state, run: false };
    case 'NEXT_OR_PREV':
      return { ...state, ...action.payload };
    case 'RESTART':
      return {
        ...state,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date(),
      };
    default:
      return state;
  }
};

// Define the Tour component
const Tour = () => {
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);
  const history = useHistory();

  useEffect(() => {
    if (!localStorage.getItem('tour')) {
      dispatch({ type: 'START' });
    }
  }, []);

  const dispatchAction = (action, status, type, index, time) => {
    setTimeout(() => {
      if (
        action === ACTIONS.CLOSE ||
        (status === STATUS.SKIPPED && tourState.run) ||
        status === STATUS.FINISHED
      ) {
        dispatch({ type: 'STOP' });
      } else if (
        type === EVENTS.STEP_AFTER ||
        type === EVENTS.TARGET_NOT_FOUND
      ) {
        dispatch({
          type: 'NEXT_OR_PREV',
          payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
        });
      }
    }, time || 300);
  };

  const callback = (data) => {
    const { action, index, type, status } = data;
    if (index === 1) {
      history.push('/text-moderation');
      dispatchAction(action, status, type, index);
    } else if (index === 13) {
      history.push('/image-moderation');
      dispatchAction(action, status, type, index);
    } else if (index === 18) {
      history.push('/users');
      dispatchAction(action, status, type, index, 2000);
    } else if (index === 23) {
      history.push({
        pathname: '/channels/messages',
        state: { channel: defaultValues.DEFAULT_CHANNEL_FOR_TOUR },
      });
      dispatchAction(action, status, type, index);
    } else if (
      action === ACTIONS.CLOSE ||
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      dispatch({ type: 'STOP' });
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      dispatch({
        type: 'NEXT_OR_PREV',
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      });
    }
  };

  return (
    <>
      <JoyRide
        {...tourState}
        callback={callback}
        spotlightPadding={5}
        showSkipButton={true}
        disableOverlayClose={true}
        styles={tourStyle}
        locale={{
          last: 'End tour',
        }}
        debug={false}
      />
    </>
  );
};
export default Tour;
