export const tisaneSettings = {
  channelID: '*',
  tisaneRiskFactorThresholdBigotry: 1,
  tisaneRiskFactorThresholdCyberBullying: 1,
  tisaneRiskFactorThresholdCriminalActivity: 1,
  tisaneRiskFactorThresholdSexualAdvances: 1,
  tisaneRiskFactorThresholdProfanity: 1,
  automaticDetectionCharacterToMaskWith: '*',
  tisaneLanguage: 'English',
  automaticDetectionModType: 'mask-message',
};

export const sightEngineSettings = {
  channelID: '*',
  sightengineRiskFactorThreshold: 0.25,
};
