import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  avatar:{
    backgroundColor:"#FCF0EF",
    color:"#E66E68",
    borderRadius:"8px",
    margin:"0px 5px 0px 0px"
  },
  button: {
    textTransform: 'none',
    fontSize: '15px',
  },
  paperRoot: {
    minWidth: '250px',
    zIndex:2
  },
  logoutIcon:{
    color:"#C2C2C2"
  }
});
