import React, { useState, useEffect } from 'react';
import { DialogTitle, Dialog, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from '../../style/createModerationModal';
import { useFormik } from 'formik';
import AddChannelForm from './AddChannelForm';
import { channelValidationSchema } from './AddChannelMetadataModal';
import AlertDialog from '../core/AlertDialog';

export default function UpdateChannelMetadataModal(props) {
  const { open, setOpen, data } = props;
  const [name, setName] = useState('');
  const [description, setDescription] = useState(null);
  const [channelID, setChannelID] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setName(data.name);
    setDescription(data.description);
    setChannelID(data.id);
  }, [data]);

  const handleClose = () => {
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      description: !description ? '' : description,
      name: name,
      id: channelID,
    },
    enableReinitialize: true,
    validationSchema: channelValidationSchema,
    onSubmit: (values) => {
      updateChannelMetadata(values);
    },
  });

  const updateChannelMetadata = (values) => {
    setAlertOpen(true);
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className={classes.modalLayout}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          disableTypography
        >
          <Typography
            testid="UpdateChannel"
            variant="h6"
            className={classes.headingFont}
          >
            Update Channel Metadata
          </Typography>
          {open ? (
            <IconButton
              testid="closeIcon"
              aria-label="close"
              onClick={handleClose}
              className={classes.closeButton}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <AddChannelForm
          formik={formik}
          handleClose={handleClose}
          buttonTitle={'UPDATE'}
          disabled={true}
        />
      </Dialog>
      <AlertDialog
        open={alertOpen}
        setOpen={setAlertOpen}
        message="This action is disabled in this demo."
      />
    </div>
  );
}
