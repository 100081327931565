import React, { useState } from "react";
import { useStyles } from "../../style/textModeration.js";
import ModerationMethods from "./ModerationMethods";
import SwitchButton from "../core/SwitchButton";
import FilterCard from "./FilterCard";

import { fetchWords, constantBoolean } from "../../utils/helpers";
import { Typography } from "@material-ui/core";
import { tisaneSettings } from "../../config";
import { actionCompleted } from "pubnub-demo-integration";
import { getSessionStorage } from "../../services/localStorage";

const TextModeration = () => {
  const classes = useStyles();
  const [profanityList, setProfanityList] = useState({
    English: "",
    Hindi: "",
    French: "",
    Portugese: "",
    Spanish: "",
  });
  const [state, setState] = useState({
    wordList: {
      wordListChannel: "*",
      applyToAllChannelIdsWordlist: true,
      wordsListPatternError: false,
      wordListLanguage: "English",
      wordListModType: "Mask-word",
      wordListReRouteMessages: false,
      wordsListMaskCharError: false,
      wordsListChannelError: false,
      wordListCharacterToMaskWith: "*",
    },
    automaticDetection: {
      applyToAllChannelIdsAutomatic: true,
      toolForAutomaticDetection: "tisane",
      siftNinjaRiskFactorThresholdVulgar: 0,
      siftNinjaRiskFactorThresholdSexting: 0,
      siftNinjaRiskFactorThresholdRacism: 0,
      siftNinjaAccountName: "",
      siftNinjaChannelName: "",
      siftNinjaApiKey: "",
      tisaneRiskFactorThresholdBigotry: tisaneSettings.tisaneRiskFactorThresholdBigotry,
      tisaneRiskFactorThresholdCyberBullying: tisaneSettings.tisaneRiskFactorThresholdCyberBullying,
      tisaneRiskFactorThresholdCriminalActivity:
        tisaneSettings.tisaneRiskFactorThresholdCriminalActivity,
      tisaneRiskFactorThresholdSexualAdvances:
        tisaneSettings.tisaneRiskFactorThresholdSexualAdvances,
      tisaneRiskFactorThresholdProfanity: tisaneSettings.tisaneRiskFactorThresholdProfanity,
      tisaneApiKey: "",
      tisaneLanguage: "Autodetect",
      automaticDetectionChannel: "*",
      automaticChannelError: false,
      automaticMaskCharError: false,
      automaticDetectionReRouteMessages: true,
      automaticDetectionModType: "mask-message",
      automaticDetectionCharacterToMaskWith: "*",
    },
    textModerationToggle: true,
    wordListProfanity: false,
    automaticProfanity: true,
    channelOnChange: false,
    saveLoading: false,
    initialLoading: false,
    errorStatus: false,
    successStatus: false,
    errorMsg: "",
    successMsg: "",
  });
  //  Interactive Demo Only
  actionCompleted({ action: "View Text Moderation", windowLocation: getSessionStorage("originalUrl") });
  //  Interactive Demo Only
  

  const handleClick = (name) => (e) => {
    e.preventDefault();
    if (name === "wordListMethod") {
      setState({
        ...state,
        wordListProfanity: true,
        automaticProfanity: false,
      });
    } else if (name === "automaticMethod") {
      setState({
        ...state,
        wordListProfanity: false,
        automaticProfanity: true,
      });
    }
  };
  const handleChange = (e) => {
    setState({ ...state, textModerationToggle: e.target.checked });
  };

  const defaultWords = async (language) => {
    let response = await fetchWords(`${process.env.PUBLIC_URL}/words/${language}.txt`);
    setProfanityList({ ...profanityList, [language]: response });
  };

  return (
    <>
      <Typography testid="title" variant="h6" className={classes.title}>
        Text Moderation
        <SwitchButton
          checked={constantBoolean(state.textModerationToggle)}
          onChange={handleChange}
          className="tour-text-toggle"
        />
      </Typography>
      <br />
      <Typography testid="sub_title" className={classes.subTitle}>
        Profanity detection method
      </Typography>

      <FilterCard state={state} handleClick={handleClick} />
      <br />
      <br />
      <ModerationMethods
        state={state}
        profanityList={profanityList}
        defaultWords={defaultWords}
        setState={setState}
        setProfanityList={setProfanityList}
      />
    </>
  );
};

export default TextModeration;
