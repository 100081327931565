import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import TextModeration from '../pages/textModeration';
import Channels from '../pages/channels';
import Overview from '../pages/overview';
import Users from '../pages/users';
import Messages from '../pages/messages';
import ImageModeration from '../pages/imageModeration';
import Tour from '../components/core/Tour';

const Routes = () => (
  <>
    <BrowserRouter basename="/pubnub-moderation-demo">
      <Switch>
        <Route path="/" exact component={Overview} />
        <Route path="/text-moderation" exact component={TextModeration} />
        <Route path="/channels" exact component={Channels} />
        <Route path="/users" exact component={Users} />
        <Route path="/channels/messages" exact component={Messages} />
        <Route path="/image-moderation" exact component={ImageModeration} />
      </Switch>
      <Tour />
    </BrowserRouter>
  </>
);

export default Routes;
