export const defaultValues = {
  PUBLISH_KEY: 'pub-c-6c0ded76-bf3d-48f8-by8e-8676a4cf7e1e',
  SUBSCRIBE_KEY: 'sub-c-6c30d752-7eb3-11eb-bd5f-5e89866287f8',
  APP_NAME: 'PubNub App',
  KEY_NAME: 'PubNub Keyset',
  MODIFIED_AT: 'August 18th 2021, 1:26 pm',
  CREATED_AT: 'August 18th 2021, 1:26 pm',
  KEY_SECRET: 'sec-c-MmJlMzQ2NzctNGRmGS00NDM5LWJkYjQtOWI5YzM3OTI4',
  USER_NAME: 'demoaccount@domain.com',
  DEFAULT_CHANNEL_FOR_TOUR: 'space_2ada61db17878cd388f95da34f9',
  PUBNUB_SALES_URL: 'https://www.pubnub.com/company/contact-sales/',
  GIT_REPO_LINK: 'https://github.com/pubnub/moderation-dashboard',
  MODERATED_CHAT_APP_URL: 'https://react-components-chat.pubnub.com/',
};
