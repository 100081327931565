import React, { useState } from 'react';
import {
  DialogTitle,
  Dialog,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from '../../style/createModerationModal';

import { checkValidChannelName } from '../../utils/helpers';
import * as yup from 'yup';
import { useFormik } from 'formik';
import AddChannelForm from './AddChannelForm';
import AlertDialog from '../core/AlertDialog';

export const channelValidationSchema = yup.object({
  id: yup
    .string('Enter channel name')
    .strict(true)
    .matches(/^\S*$/, 'Channel ID must not contain spaces')
    .test(
      'Channel ID not valid',
      'Channel ID not valid',
      (value) => !checkValidChannelName(value)
    )
    .required('Channe ID is required'),
  name: yup.string('Enter display name').required('Display name is required'),
});

export default function AddChannelMetadataModal({ pubnubObject, isAdded }) {
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      description: '',
      name: '',
      id: '',
    },
    validationSchema: channelValidationSchema,
    onSubmit: (values) => {
      addNewChannelMetadata(values);
    },
  });

  const addNewChannelMetadata = (values) => {
    setAlertOpen(true);
  };

  return (
    <div>
      <Button
        id="addChannel"
        startIcon={
          <img
            src={process.env.PUBLIC_URL + '/images/add-channel.svg'}
            alt="add"
          />
        }
        onClick={handleClickOpen}
        className={classes.addChannelButton}
      >
        <Typography className={classes.addChannelButtonFont}>
          Add Channel
        </Typography>
      </Button>
      <Dialog
        id="dialog"
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className={classes.modalLayout}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          disableTypography
        >
          <Typography variant="h6" className={classes.headingFont}>
            Add Channel
          </Typography>
          {open ? (
            <IconButton
              id="closeButton"
              aria-label="close"
              onClick={handleClose}
              className={classes.closeButton}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <AddChannelForm
          id="add"
          formik={formik}
          handleClose={handleClose}
          buttonTitle={'ADD'}
          disabled={false}
        />
      </Dialog>
      <AlertDialog
        open={alertOpen}
        setOpen={setAlertOpen}
        message="This action is disabled in this demo."
      />
    </div>
  );
}
