import React from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import { useStyles } from '../../style/textModeration.js';

const FilterCard = ({ state }) => {
  const classes = useStyles();
  return (
    <Grid container justify="flex-start" spacing={3}>
      <Grid item>
        <Box className={classes.methodSelected}>
          <Grid container justify="center">
            <Box p={1}>
              <img
                alt=""
                src={
                  process.env.PUBLIC_URL +
                  '/images/automatic-profanity-selected.svg'
                }
              />
            </Box>
          </Grid>
          <Typography
            testid="autoDetection"
            variant="body1"
            align="center"
            className="tour-sightengine"
          >
            Automatic Detection
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FilterCard;
