const automaticDetectionText = (
  <>
    <span>Automatic Detection uses an API from </span>
    <a
      style={{ fontWeight: 600, color: 'black', textDecorationLine: 'none' }}
      href="https://tisane.ai"
      target="_blank"
      rel="noopener noreferrer"
    >
      Tisane.ai
    </a>
    <span>
      {' '}
      that can analyze messages for things like cyberbullying, hate speech, etc
    </span>
  </>
);

const tisaneApiKey = (
  <>
    <span>Specify the API key you got when you signed up for a free </span>
    <a
      style={{ fontWeight: 600, color: 'black', textDecorationLine: 'none' }}
      href="https://tisane.ai"
      target="_blank"
      rel="noopener noreferrer"
    >
      Tisane.ai
    </a>
    <span> account</span>
  </>
);

const imageModerationText = (
  <>
    <span>Image moderation is provided through an API from </span>
    <a
      style={{ fontWeight: 600, color: 'black', textDecorationLine: 'none' }}
      href="https://sightengine.com/"
      target="_blank"
      rel="noopener noreferrer"
    >
      SightEngine.
    </a>

    <span>
      {' '}
      After creating a free account you will need to provide an API user and
      key.
    </span>
  </>
);

const sightEngineWorkflow = (
  <>
    <span>On the Sightengine website you can </span>
    <a
      style={{ fontWeight: 600, color: 'black', textDecorationLine: 'none' }}
      href="https://sightengine.com/docs/image-moderation-workflows"
      target="_blank"
      rel="noopener noreferrer"
    >
      create a workflow
    </a>
    <span>
      {' '}
      that defines the types of image moderation to perform. You’ll need to
      provide the workflow ID here
    </span>
  </>
);

// Moderation Dashboard tour steps
export const TOUR_STEPS = [
  {
    target: '.tour-settings',
    content:
      'Click Settings to configure the Text or Image Moderation Settings',
    placement: 'right',
  },
  {
    target: '.tour-redirect-text-moderation',
    content: '',
  },
  {
    target: '.tour-text-toggle',
    content: 'Enable or disable text moderation here',
  },
  {
    target: '.tour-select-wordlist',
    content:
      'Word List moderation allows you to block or mute messages that contain specific words based on a list you provide',
  },
  {
    target: '.tour-select-automatic',
    content: automaticDetectionText,
  },
  {
    target: '.tour-channel-pattern',
    content: 'Specify the Channel pattern to apply moderation to',
  },
  {
    target: '.tour-specify-tisane-api-key',
    content: tisaneApiKey,
  },
  {
    target: '.tour-specify-language',
    content: 'Specify the language to analyze for moderation',
  },
  {
    target: '.tour-tisane-thresholds',
    content:
      'Set the threshold for Bigotry, Personal Attacks, Criminal Activity, Sexual Advances, Profanity.  Moving the sliders to the right makes it more likely that moderation will apply',
  },
  {
    target: '.tour-mask-words',
    content: 'Choose Mask word or Block message',
  },
  {
    target: '.tour-tisane-route',
    content:
      'Choose if moderated messages will go to a blocked channel visible in the dashboard by Admins',
  },
  {
    target: '.tour-save-function',
    content:
      'Click Save.  This will push a function to the PubNub account being moderated',
  },
  {
    target: '.tour-image-moderation',
    content: 'Choose Image Moderation under the Settings menu',
    placement: 'right',
  },
  {
    target: '.tour-redirect-image-moderation',
    content: '',
  },
  {
    target: '.tour-image-toggle',
    content: 'Enable or disable image moderation here',
  },
  {
    target: '.tour-sightengine-user',
    content: imageModerationText,
  },
  {
    target: '.tour-sightengine-id',
    content: sightEngineWorkflow,
  },
  {
    target: '.tour-users',
    content: 'Select Users to view Users of the chat app.',
    placement: 'right',
  },
  {
    target: '.tour-redirect-users',
    content: '',
  },
  {
    target: '.tour-filter-users',
    content:
      'The filter view will allow the Admin to see Banned and Flag users',
  },
  {
    target: '.tour-flag-user',
    content:
      'The Flag Icon will flag a user for future moderation action (or unflag them)',
  },
  {
    target: '.tour-ban-user',
    content:
      'The Ban/Unban Icon will prevent/allow the user from using the chat app',
  },
  {
    target: '.tour-channels',
    content:
      'The channels view will allow the Admin to see the list of channels.  Click on a channel to view message history. (History must be enabled in the PubNub application)',
    placement: 'right',
  },
  {
    target: '.tour-redirect-channels',
    content: '',
  },
  {
    target: '.tour-banned-channel',
    content: 'Admin can view messages in the blocked channel (if enabled)',
  },
  {
    target: '.tour-mute-user',
    content: 'Admin can mute or un-mute a user for a channel',
  },
  {
    target: '.tour-block-user',
    content: 'Admin can block or un-block a user for a channel',
  },
  {
    target: '.tour-view-chat',
    content:
      'Click here to launch a chat application and try out these features!',
  },
];
