import React, { useState, useEffect } from 'react';
import { DialogTitle, Dialog, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from '../../style/createModerationModal';
import { useFormik } from 'formik';
import AddUserForm from './AddUserForm';
import { userValidationSchema } from './AddUserMetadataModal';
import AlertDialog from '../core/AlertDialog';

export default function UpdateUserMetadataModal(props) {
  const { open, setOpen, data } = props;
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState(null);
  const [UUID, setUUID] = useState('');
  const [profileUrl, setProfileUrl] = useState(null);
  const classes = useStyles();
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    setUserName(data.name);
    setEmail(data.email);
    setUUID(data.id);
    setProfileUrl(data.profileUrl);
  }, [data]);

  const handleClose = () => {
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      email: !email ? '' : email,
      name: userName,
      user_id: UUID,
      profile_url: !profileUrl ? '' : profileUrl,
    },
    enableReinitialize: true,
    validationSchema: userValidationSchema,
    onSubmit: (values) => {
      updateUserMetadata(values);
    },
  });

  const updateUserMetadata = (values) => {
    setAlertOpen(true);
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className={classes.modalLayout}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          disableTypography
        >
          <Typography variant="h6" className={classes.headingFont}>
            Update User Metadata
          </Typography>
          {open ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              className={classes.closeButton}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <AddUserForm
          formik={formik}
          handleClose={handleClose}
          buttonTitle={'UPDATE'}
          disabled={true}
        />
      </Dialog>
      <AlertDialog
        open={alertOpen}
        setOpen={setAlertOpen}
        message="This action is disabled in this demo."
      />
    </div>
  );
}
