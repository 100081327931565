import React from 'react';
import { NavigateNext } from '@material-ui/icons';
import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import { defaultValues } from '../../../constants';

export default function Breadcrumb(props) {
  return (
    <div>
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
      >
        {props.currentPage ? (
          <Link color="primary" href={process.env.PUBLIC_URL + '/'}>
            <Typography color="primary" variant="body2">
              {defaultValues.KEY_NAME}
            </Typography>
          </Link>
        ) : (
          <Typography color="inherit" variant="body2">
            {defaultValues.KEY_NAME}
          </Typography>
        )}
        {props.currentPage ? (
          <Typography color="inherit" variant="body2">
            {props.currentPage}
          </Typography>
        ) : null}
      </Breadcrumbs>
    </div>
  );
}
